import loginService from "@/services/login.service";
import Vue from "vue";
import VueRouter from "vue-router";

import PrivateLayout from "../layout/PrivateLayout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: PrivateLayout,
    children: [
      {
        path: "",
        name: "init",
        // component: () => import("../views/dashboard/Index.vue"),
        component: () => import("../views/transactions/Index.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/Index.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/dashboard/Index.vue"),
      },
      {
        path: "/reports",
        name: "reports",
        component: () => import("../views/reports/Index.vue"),
      },
      {
        path: "/export-billing",
        name: "export-billing",
        component: () => import("../views/export/BillingIndex.vue"),
      },
      {
        path: "/export-billing-group",
        name: "export-billing-group",
        component: () => import("../views/export/BillingGroupIndex.vue"),
      },
      {
        path: "/export-video",
        name: "export-video",
        component: () => import("../views/export/VideoIndex.vue"),
      },
      {
        path: "/export-traffic",
        name: "export-traffic",
        component: () => import("../views/export/TrafficIndex.vue"),
      },
      {
        path: "/export-traffic-group",
        name: "export-traffic-group",
        component: () => import("../views/export/TrafficGroupIndex.vue"),
      },
      {
        path: "/toll-transactions",
        name: "toll-transactions",
        component: () => import("../views/transactions/Index.vue"),
      },
      {
        path: "/toll-snapshots",
        name: "toll-snapshots",
        component: () => import("../views/snapshot/Index.vue"),
      },
      {
        path: "/toll-lanes",
        name: "toll-lanes",
        component: () => import("../views/lanes/Index.vue"),
      },
      {
        path: "/toll-settlement-pre",
        name: "toll-settlement-pre",
        component: () => import("../views/settlement/IndexPre.vue"),
      },
      {
        path: "/toll-settlement",
        name: "toll-settlement",
        component: () => import("../views/settlement/IndexFinal.vue"),
      },
      {
        path: "/toll-settlement/validate",
        name: "toll-settlement-validate",
        component: () => import("../views/settlement/IndexValidation.vue"),
      },
      {
        path: "/etc-items",
        name: "etc-items",
        component: () => import("../views/tag/AccountIndex.vue"),
      },
      {
        path: "/etc-delivery",
        name: "etc-delivery",
        component: () => import("../views/tag/DeliveryIndex.vue"),
      },
      {
        path: "/etc-receive",
        name: "etc-receive",
        component: () => import("../views/tag/ReceiveIndex.vue"),
      },
      {
        path: "/etc-import",
        name: "etc-import",
        component: () => import("../views/tag/ImportIndex.vue"),
      },
      {
        path: "/catalogs-turns",
        name: "catalogs-turns",
        component: () => import("../views/catalogs/TurnsIndex.vue"),
      },
      {
        path: "/catalogs-classification",
        name: "catalogs-classification",
        component: () => import("../views/catalogs/ClassificationIndex.vue"),
      },
      {
        path: "/catalogs-payment-types",
        name: "catalogs-payment-types",
        component: () => import("../views/catalogs/PaymentTypesIndex.vue"),
      },
      {
        path: "/catalogs-gates",
        name: "catalogs-gates",
        component: () => import("../views/catalogs/GatesIndex.vue"),
      },
      {
        path: "/catalogs-gates-configuration",
        name: "catalogs-gates-configuration",
        props: true,
        component: () => import("../views/catalogs/GatesConfiguration.vue"),
      },
      {
        path: "/catalogs-gates-monitor",
        name: "catalogs-gates-monitor",
        props: true,
        component: () => import("../views/catalogs/GatesMonitor.vue"),
      },
      {
        path: "/catalogs-devices",
        name: "catalogs-devices",
        component: () => import("../views/catalogs/DeviceIndex.vue"),
      },
      {
        path: "/catalogs-excepted",
        name: "catalogs-excepted",
        component: () => import("../views/catalogs/ExceptedIndex.vue"),
      },
      {
        path: "/monitor-video",
        name: "monitor-video",
        component: () => import("../views/monitor/VideoServerIndex.vue"),
      },
      {
        path: "/admin-users",
        name: "admin-users",
        component: () => import("../views/admin/UsersIndex.vue"),
      },
      {
        path: "/admin-roles",
        name: "admin-roles",
        component: () => import("../views/admin/RolesIndex.vue"),
      },
      {
        path: "/admin-permissions",
        name: "admin-permissions",
        component: () => import("../views/admin/PermissionsIndex.vue"),
      },
      {
        path: "/admin-configurations",
        name: "admin-configurations",
        component: () => import("../views/admin/ConfigurationIndex.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/Index.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/public/restore"];

  const authRequired = !publicPages.includes(to.path);

  const loggedIn = loginService.isAuthenticated(false);

  if (authRequired && !loggedIn) {
    if (from.path == "/login") return;
    return next("login").catch(() => {});
  }

  next();
});

export default router;
